/*---------------------------------------
    SIMPLE FRAMEWORK
---------------------------------------*/

*, *:before, *:after { box-sizing: border-box; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

// MIXINS
  // TRANSITION
  @mixin transition($duration: 0.25s, $animation: linear) {
    transition: all $duration $animation;
  }

  // PLACEHOLDER
  @mixin placeholder {
    &::-webkit-input-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &:-moz-placeholder { @content; }
  }

  // CLEARFIX
  @mixin clfix {
    &:after { content: ''; display: block; height: 0; clear: both; visibility: hidden; }
  }

  // CENTERED BOX
  @mixin centered {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
  }

  // RESPONSIVE
  @mixin large { @media (max-width: $large) { @content; } }
  @mixin desktop { @media (max-width: $desktop) { @content; } }
  @mixin tablet { @media (max-width: $tablet) { @content; } }
  @mixin phone { @media (max-width: $phone ) { @content; } }

html {
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
}

img {
  display: block;
}

input,
textarea,
button,
select {
  font-size: inherit;
  font-family: inherit;
  outline: 0;
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  @include transition;
  @include placeholder {
    color: #bdbdbd;
  };
}

input[type=submit],
input[type=reset],
button {
  cursor: pointer;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
  @include transition;
}

.link {
  text-decoration: underline;
  &:hover {
    color: $color-prime;
  }
}

p {
  padding: 0; margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  // line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 400;
  color: inherit;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 1.9375rem;
}

h4 {
  font-size: 1.5625rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: .85em 1em;
  outline: none;
  -webkit-appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background: none;
  color: #fefefe;
  @include transition;
}

.input, .textarea {
  display: block;
  width: 100%;
  padding: .8rem;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: none;
  color: inherit;
  appearance: none;
  @include transition;
}

.textarea {
  resize: none;
}

.select {
  display: block;
  width: 100%;
  padding: .8rem;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: none;
  color: inherit;
  // отключить стрелочку, чтобы нарисовать свою
  // appearance: none;
  @include transition;
}

.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }

.hide {
  display: none;
}

.show {
  display: block;
}

/*---------------------------------------
    CONTAINERS (КОНТЕЙНЕРЫ)
---------------------------------------*/
.display-table {
  display: table;
  width: 100%; height: 100%;
  &-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.fixed {
  position: fixed !important;
}

/*---------- ТЕКСТ ----------*/
.text {
  font-family: inherit;
  font-weight: inherit;
  p + p {
    margin-top: 1rem;
  }
  ol {
    padding: 0 0 0 1rem;
  }
  ul {
    padding: 0;
    list-style: none;
  }
}