@import '_settings';
@import '_framework';

html {
  height: 100%;
  width: 100%;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  @include large {
    font-size: 14px;
  }
  @include tablet {
    font-size: 12px;
  }
  @include phone {
    font-size: 11px;
  }
}

body {
  height: 100%;
  font-family: $opensans;
  color: $color-text;
  font-weight: 400;
  position: relative;
  overflow-x: hidden;
}

@import '_elements';

.container {
  max-width: $width;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  @include large {
    max-width: 960px;
  }
}

.btn {
  display: inline-block;
  background-color: #ef5350;
  color: #fff;
  border-color: #ef5350;
  border-radius: 50px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  min-width: 150px;
  &:hover {
    background-color: #d32f2f;
  }
}

// import

/*---------------------------------------
    HEADER
---------------------------------------*/
.topper {
  padding: 5px 0;
  background: #fff;
  @include desktop {
    padding: 15px 0;
  }
  &__item {
    position: relative;
    vertical-align: middle;
    @include desktop {
      margin-bottom: 15px;
      vertical-align: top;
    }
    &--actions {
      text-align: right;
      @include desktop {
        text-align: center;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }
  }
}

.logotype {
  &__img {
    display: inline-block;
    width: 100%;
    max-width: 230px;
  }
}

.menu {
  margin: 0 0 0 30px; padding: 0; list-style: none;
  @include clfix;
  &__section {
    float: left;
    & + .menu__section {
      margin-left: 50px;
    }
  }
  &__item {
    display: block;
    margin-bottom: 5px;
  }
  &__link {
    font-size: 12px;
    font-weight: 600;
    color: $color-text;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    &:hover {
      color: lighten($color-text, 30%);
    }
    &:active {
      color: $color-prime;
    }
  }
  &__close {
    display: none;
  }
  @include desktop {
    position: fixed;
    top: 0; left: 0;
    width: 100%; height: 100%;
    z-index: -9999;
    opacity: 0;
    margin: 0;
    @include transition;
  }
  &.active {
    opacity: 1;
    top: 0; left: 0; bottom: 0; right: 0;
    background: rgba(#000, 0.9);
    z-index: 100;
    margin: 0;
    text-align: center;
    padding: 80px 0 0;
    @include transition;
    .menu__section {
      float: none;
      width: 100%;
      text-align: center;
      & + .menu__section {
        margin-left: 0;
      }
    }
    .menu__link {
      display: block;
      text-align: center;
      color: #fff;
      font-size: 20px;
      padding: 15px 0;
      &:hover {
        color: lighten($color-text, 40%);
      }
    }
    .menu__close {
      display: block;
      position: absolute;
      cursor: pointer;
      width: 60px; height: 60px;
      padding: 15px;
      border: 1px solid lighten($color-text, 60%);
      border-radius: 50%;
      top: 80%; left: 50%;
      margin-left: -30px;
      .svg {
        display: block;
        position: absolute;
        width: 30px; height: 30px;
        fill: #fff;
        @include transition;
      }
      &:hover {
        .svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.mobile-ico {
  display: none;
  @include desktop {
    display: block;
    position: absolute;
    right: 15px; top: 0;
  }
}

.contact {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-right: 5px;
  margin-bottom: 15px;
  &__ico {
    display: inline-block;
    vertical-align: middle;
    width: 20px; height: 20px;
    position: relative;
    .svg {
      display: inline-block;
      height: 100%;
      width: 100%;
      fill: lighten($color-text, 20%);
    }
  }
  &__txt {
    display: inline-block;
    vertical-align: middle;
  }
  &__link {
    color: $color-text;
    &:hover {
      color: lighten($color-text, 30%);
    }
  }
}

.header-content {
  padding: 30px 0 20px;
  position: relative;
  z-index: 1;
  @include clfix;

  &__item {
    @include desktop {
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .bg {
    position: absolute;
    top: 0; left: 0; width: 100%; height: 100%;
    background: url('../images/bg.jpg') no-repeat center;
    background-size: cover;
    z-index: -1;
    &:before {
      content: ''; display: block;
      position: absolute;
      top: 0; left: 0; width: 100%; height: 100%;
      background: #000;
      opacity: 0.5;
    }
  }
}

.caption {
  margin: 0; padding: 0; list-style: none;
  &__item {
    display: block;
    margin-bottom: 5px;
    line-height: 1.2;
    color: #fff;
  }
  &__txt {
    display: inline-block;
    padding: 5px 10px;
    font-size: 42px;
    text-transform: uppercase;
    background-color: rgba(#000, 0.65);
    @include transition;
    @include phone {
      font-size: 1.8rem;
    }
    &:hover {
      background-color: $color-prime;
    }
  }
  &__subtitle {
    display: inline-block;
    font-size: 28px;
    padding: 5px 10px;
    margin-top: 15px;
    background-color: rgba(#000, 0.65);
  }
  a.caption__subtitle {
    &:hover {
      background-color: $color-prime;
    }
  }
}

/*---------------------------------------
    FORM
---------------------------------------*/
.form {
  display: inline-block;
  text-align: center;
  background: #fff;
  padding: 30px 20px 20px;
  max-width: 380px;
  border-radius: 5px;
  line-height: 1.6;
  .form__title {
    margin-bottom: 15px;
  }
  .form__subtitle {
    font-size: 12px;
    margin-bottom: 15px;
    color: lighten($color-text, 40%);
  }
  .form__footer {
    font-size: 12px;
    margin-bottom: 15px;
    color: lighten($color-text, 40%);
  }
}

.action-form {
  .result {
    .success {
      color: $color-success;
      margin-bottom: 24px;
    }
    .error {
      color: $color-error;
      margin-bottom: 24px;
    }
  }
  .form-group {
    display: block;
    text-align: left;
    margin-bottom: 15px;
  }
  .form-label {
    display: block;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 3px;
    .required {
      color: $color-error;
    }
  }
  .form-input {
    border: 1px solid lighten($color-text, 60%);
    border-radius: 4px;
    padding: 8px 10px;
    &:focus {
      border-color: $color-text;
    }
  }
  .form-btn {
    width: 100%;
    background: #ef5350;
    padding: 12px 16px;
    &:hover {
      background-color: #d32f2f;
    }
  }

  .has-error {
    color: $color-error;
  }

  .form-error {
    font-size: 0.8em;
    color: $color-error;
  }
}

.checkbox {
  cursor: pointer;
  display: block;
  position: relative;
  vertical-align: middle;
  input {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  .styling {
    display: inline-block;
    vertical-align: middle;
    width: 12px; height: 12px;
    border: 1px solid lighten($color-text, 40%);
    border-radius: 2px;
    margin-right: 5px;
    position: relative;
    &:before {
      display: block; content: '';
      position: absolute;
      top: -8px; left: 0;
      color: $color-success;
    }
  }
  .label {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: lighten($color-text, 40%);
  }
  input[type=checkbox]:checked + .styling {
    &:before {
      content: '\2714';
    }
  }
}

/*---------------------------------------
    SECTIONS
---------------------------------------*/
.section {
  padding: 50px 0;
  &-colored {
    background-color: $color-bg;
  }
  &-title {
    h2 {
      margin-bottom: 15px;
    }
    &--smaller {
      h2 {
        font-size: 24px;
      }
    }
    text-align: center;
    margin-bottom: 3rem;
    &--upper {
      text-transform: uppercase;
    }
    &-sub {
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      color: lighten($color-text, 10%);
    }
  }
}

/*---------------------------------------
    CARDS
---------------------------------------*/
.cards {
  margin: 0 -10px;
  &--push {
    margin-bottom: 30px;
  }
  &__item {
    margin-bottom: 1rem;
    &--bordered {
      padding: 0 10px;
      margin-bottom: 20px;
    }
    @include tablet {
      margin-bottom: 2rem;
    }
  }
  @include desktop {
    margin: 0;
  }
}

.card {
  padding: 0 20px;
  text-align: center;
  line-height: 26px;
  position: relative;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;



  &__body {
    flex: 0 1 100%;
    position: relative;
  }

  &__num {
    width: 32px; height: 32px;
    background: $color-prime;
    border-radius: 50%;
    color: #fff;
    line-height: 32px;
    margin: 15px auto;
  }

  &__cont {
    padding-bottom: 20px;
  }
  &__footer {
    flex: 0 1 100%;
    align-self: flex-end;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  &__txt {
    font-size: 14px;
    p + p {
      margin-top: 15px;
    }
  }
  &__pic {
    flex: 0 1 100%;
    height: 160px;
    overflow: hidden;
    position: relative;
    @include tablet {
      height: 220px;
    }
  }
  &__price {
    font-weight: 600;
    margin-bottom: 10px;
    &--colored {
      color: $color-prime;
    }
  }

  &--bordered {
    background: #fff;
    padding: 0 0 20px;
    border: 1px solid lighten($color-text, 80%);
    .card__cont {
      padding: 20px;
    }
  }
  &--full {
    padding-bottom: 0;

    .card__cont {
      padding-right: (50%) + 2%;
      @include phone {
        padding-right: 0;
      }
    }
    .card__footer {
      padding-right: (50%) + 2%;
      margin-bottom: 20px;
      @include phone {
        padding-right: 0;
      }
    }
    .card__pic {
      position: absolute;
      top: 0; right: 0;
      height: 100%; width: (50%);
      @include phone {
        position: static;
        width: 100%; height: 200px;
      }
    }
    .card__title {
      font-size: 32px;
      line-height: 1.4;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    .card__txt {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}

.img-bg {
  position: relative;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include transition;
  &:hover {
    transform: scale(1.1);
  }
  &--top {
    background-position: top center;
  }
  &--no-zoom {
    &:hover {
      transform: none;
    }
  }
}

.img {
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
}


/*---------------------------------------
    CLIENTS
---------------------------------------*/
.clients {
  text-align: center;
  &__item {
    padding: 0 20px 20px;
    margin-bottom: 20px;
    vertical-align: middle;
    text-align: center;
  }
}

.client {
  img {
    display: inline-block;
    width: 100%;
    @include transition;
    &:hover {
      transform: scale(1.1);
    }
  }
}

/*---------------------------------------
    SLIDER
---------------------------------------*/
.slider-license {
  padding: 0 30px;

  .slick-arrow {
    position: absolute;
    width: 30px; height: 30px;
    top: 50%; margin-top: -15px;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    @include transition;
    &:hover {
      color: $color-prime;
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }

  .slick-dots {
    margin: 0; padding: 0; list-style: none;
    text-align: center;

    li {
      display: inline-block;
      width: 10px; height: 10px;
      background: lighten(#ccc, 0.2);
      border-radius: 50%;
      margin: 15px 5px 0;
      overflow: hidden;

      button {
        color: transparent;
      }

      &.slick-active {
        background: $color-prime;
      }
    }
  }
}

.slide {
  height: 400px;
  position: relative;
  z-index: 1;
  @include tablet {
    height: 300px;
  }
  @include phone {
    height: 220px;
  }
  &__img {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100%;
    z-index: -1;
  }
  &__link {
    display: block;
    position: relative;
    height: 100%;
    z-index: 1;
    &:before {
      display: block; content: '';
      visibility: hidden; opacity: 0;
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background: rgba(#000, 0.6);
      @include transition;
    }
    .zoom-in {
      display: block;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 50px; height: 50px;
      fill: $color-prime;
      top: 50%; left: 50%;
      margin-top: -20px; margin-left: -20px;
      z-index: 10;
      @include transition;
    }
    &:hover {
      &:before {
        visibility: visible; opacity: 1;
      }
      .zoom-in {
        visibility: visible;
        opacity: 0.8;
      }
    }
  }
}

/*---------------------------------------
    MAP
---------------------------------------*/
.map {
  height: 400px;
  overflow: hidden;
  // padding-bottom: 56.25%;
  position:relative;
  // height: 100%;
  iframe {
    position:absolute;
    left: 0; top: 0;
    height: 100%; width: 100%;
  }
}

/*---------------------------------------
    FOOTER
---------------------------------------*/
.footer {
  padding: 20px 0;

  &__item {
    vertical-align: middle;
    @include tablet {
      text-align: center;
      margin-bottom: 15px;
    }
  }
}
.footer-slogan {
  font-weight: 600;
}

.footer-logo {
  text-align: center;
  &__img {
    display: inline-block;
    width: 100%;
    max-width: 230px;
  }
}

.footer-contacts {
  margin: 0; padding: 0; list-style: none;
  text-align: right;
  li {
    display: block;
    margin-bottom: 5px;
  }
  a {
    color: $color-text;
    font-size: 12px;
    &:hover {
      color: $color-prime;
    }
  }
  @include tablet {
    text-align: center;
  }
}

.footer-links {
  font-size: 10px;
  text-align: right;
  a {
    display: inline-block;
    margin: 0 5px;
    color: $color-text;
    &:hover {
      color: $color-prime;
    }
  }
  @include tablet {
    text-align: center;
  }
}

/*---------------------------------------
    MODAL
---------------------------------------*/
.modal {
  display: none;
  border-radius: 10px;
}

.modal-text {
  max-width: 80%;
  padding: 40px 20px;
}