// SETTINGS

  // FONTS
  $opensans: 'Open Sans', 'Helvetica', 'Arial', sans-serif;

  // COLORS
  $color-prime: #dd4b39;
  $color-text: #212121;
  $color-bg: #f7f7f7;

  $color-error: #b71c1c;
  $color-success: #388e3c;

  // TEXT
  $text: 16px;

  // CONTAINER
  $width: 1170px;

  // BREACKPOINTS

  //Bootstrap:
    // $large: 1200px;
    // $desktop: 992px;
    // $tablet: 768px;
    // $phone: 480px;

  $large: 1200px;
  $desktop: 900px;
  $tablet: 640px;
  $phone: 480px;

  /*
  * BREAK POINTS
  *
  * Large < #{$large} (lg)
  * Desktop < #{$desktop} (md)
  * Tablet < #{$tablet} (sm)
  * Phone < #{$phone} (xs)
  *
  */