@charset "UTF-8";
/*
  * BREAK POINTS
  *
  * Large < 1200px (lg)
  * Desktop < 900px (md)
  * Tablet < 640px (sm)
  * Phone < 480px (xs)
  *
  */
/*---------------------------------------
    SIMPLE FRAMEWORK
---------------------------------------*/
*, *:before, *:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  padding: 0; }

img {
  display: block; }

input,
textarea,
button,
select {
  font-size: inherit;
  font-family: inherit;
  outline: 0;
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  transition: all 0.25s linear; }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  button::-webkit-input-placeholder,
  select::-webkit-input-placeholder {
    color: #bdbdbd; }
  input::-moz-placeholder,
  textarea::-moz-placeholder,
  button::-moz-placeholder,
  select::-moz-placeholder {
    color: #bdbdbd; }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  button:-ms-input-placeholder,
  select:-ms-input-placeholder {
    color: #bdbdbd; }
  input:-moz-placeholder,
  textarea:-moz-placeholder,
  button:-moz-placeholder,
  select:-moz-placeholder {
    color: #bdbdbd; }

input[type=submit],
input[type=reset],
button {
  cursor: pointer; }

a {
  color: inherit;
  outline: none;
  text-decoration: none;
  transition: all 0.25s linear; }

.link {
  text-decoration: underline; }
  .link:hover {
    color: #dd4b39; }

p {
  padding: 0;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 400;
  color: inherit; }

h1 {
  font-size: 3rem; }

h2 {
  font-size: 2.5rem; }

h3 {
  font-size: 1.9375rem; }

h4 {
  font-size: 1.5625rem; }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 1rem; }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: .85em 1em;
  outline: none;
  -webkit-appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background: none;
  color: #fefefe;
  transition: all 0.25s linear; }

.input, .textarea {
  display: block;
  width: 100%;
  padding: .8rem;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: none;
  color: inherit;
  appearance: none;
  transition: all 0.25s linear; }

.textarea {
  resize: none; }

.select {
  display: block;
  width: 100%;
  padding: .8rem;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: none;
  color: inherit;
  transition: all 0.25s linear; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.hide {
  display: none; }

.show {
  display: block; }

/*---------------------------------------
    CONTAINERS (КОНТЕЙНЕРЫ)
---------------------------------------*/
.display-table {
  display: table;
  width: 100%;
  height: 100%; }
  .display-table-cell {
    display: table-cell;
    vertical-align: middle; }

.fixed {
  position: fixed !important; }

/*---------- ТЕКСТ ----------*/
.text {
  font-family: inherit;
  font-weight: inherit; }
  .text p + p {
    margin-top: 1rem; }
  .text ol {
    padding: 0 0 0 1rem; }
  .text ul {
    padding: 0;
    list-style: none; }

html {
  height: 100%;
  width: 100%;
  font-size: 16px;
  -webkit-font-smoothing: antialiased; }
  @media (max-width: 1200px) {
    html {
      font-size: 14px; } }
  @media (max-width: 640px) {
    html {
      font-size: 12px; } }
  @media (max-width: 480px) {
    html {
      font-size: 11px; } }

body {
  height: 100%;
  font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
  color: #212121;
  font-weight: 400;
  position: relative;
  overflow-x: hidden; }

/* ELEMENTS */
/*---------- HAMBURGER ----------*/
.hamburger {
  cursor: pointer;
  display: block;
  height: 1.5rem;
  width: 2.5rem;
  position: relative; }
  .hamburger span, .hamburger:before, .hamburger:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 0.1875rem;
    background: #212121;
    border-radius: 2px;
    transition: all 0.25s linear; }
  .hamburger:before {
    top: 0; }
  .hamburger span {
    top: 43%; }
  .hamburger:after {
    bottom: 0; }
  .hamburger.active span {
    background: none; }
  .hamburger.active:before {
    top: 6px;
    transform: rotate(45deg); }
  .hamburger.active:after {
    top: 6px;
    transform: rotate(-45deg); }

.app-author {
  text-align: center;
  text-transform: uppercase;
  color: #bababa;
  font-size: 0.6em;
  padding: 1rem 0 0; }
  .app-author__link {
    color: #bababa; }
    .app-author__link:hover {
      color: #dd4b39; }

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px; }
  @media (max-width: 1200px) {
    .container {
      max-width: 960px; } }

.btn {
  display: inline-block;
  background-color: #ef5350;
  color: #fff;
  border-color: #ef5350;
  border-radius: 50px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  min-width: 150px; }
  .btn:hover {
    background-color: #d32f2f; }

/*---------------------------------------
    HEADER
---------------------------------------*/
.topper {
  padding: 5px 0;
  background: #fff; }
  @media (max-width: 900px) {
    .topper {
      padding: 15px 0; } }
  .topper__item {
    position: relative;
    vertical-align: middle; }
    @media (max-width: 900px) {
      .topper__item {
        margin-bottom: 15px;
        vertical-align: top; } }
    .topper__item--actions {
      text-align: right; }
      @media (max-width: 900px) {
        .topper__item--actions {
          text-align: center;
          vertical-align: middle;
          margin-bottom: 0; } }

.logotype__img {
  display: inline-block;
  width: 100%;
  max-width: 230px; }

.menu {
  margin: 0 0 0 30px;
  padding: 0;
  list-style: none; }
  .menu:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }
  .menu__section {
    float: left; }
    .menu__section + .menu__section {
      margin-left: 50px; }
  .menu__item {
    display: block;
    margin-bottom: 5px; }
  .menu__link {
    font-size: 12px;
    font-weight: 600;
    color: #212121;
    text-transform: uppercase;
    letter-spacing: 0.1em; }
    .menu__link:hover {
      color: #6e6e6e; }
    .menu__link:active {
      color: #dd4b39; }
  .menu__close {
    display: none; }
  @media (max-width: 900px) {
    .menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -9999;
      opacity: 0;
      margin: 0;
      transition: all 0.25s linear; } }
  .menu.active {
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 100;
    margin: 0;
    text-align: center;
    padding: 80px 0 0;
    transition: all 0.25s linear; }
    .menu.active .menu__section {
      float: none;
      width: 100%;
      text-align: center; }
      .menu.active .menu__section + .menu__section {
        margin-left: 0; }
    .menu.active .menu__link {
      display: block;
      text-align: center;
      color: #fff;
      font-size: 20px;
      padding: 15px 0; }
      .menu.active .menu__link:hover {
        color: #878787; }
    .menu.active .menu__close {
      display: block;
      position: absolute;
      cursor: pointer;
      width: 60px;
      height: 60px;
      padding: 15px;
      border: 1px solid #bababa;
      border-radius: 50%;
      top: 80%;
      left: 50%;
      margin-left: -30px; }
      .menu.active .menu__close .svg {
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        fill: #fff;
        transition: all 0.25s linear; }
      .menu.active .menu__close:hover .svg {
        transform: rotate(180deg); }

.mobile-ico {
  display: none; }
  @media (max-width: 900px) {
    .mobile-ico {
      display: block;
      position: absolute;
      right: 15px;
      top: 0; } }

.contact {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-right: 5px;
  margin-bottom: 15px; }
  .contact__ico {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    position: relative; }
    .contact__ico .svg {
      display: inline-block;
      height: 100%;
      width: 100%;
      fill: #545454; }
  .contact__txt {
    display: inline-block;
    vertical-align: middle; }
  .contact__link {
    color: #212121; }
    .contact__link:hover {
      color: #6e6e6e; }

.header-content {
  padding: 30px 0 20px;
  position: relative;
  z-index: 1; }
  .header-content:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }
  @media (max-width: 900px) {
    .header-content__item {
      text-align: center;
      margin-bottom: 15px; } }
  .header-content .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../images/bg.jpg") no-repeat center;
    background-size: cover;
    z-index: -1; }
    .header-content .bg:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.5; }

.caption {
  margin: 0;
  padding: 0;
  list-style: none; }
  .caption__item {
    display: block;
    margin-bottom: 5px;
    line-height: 1.2;
    color: #fff; }
  .caption__txt {
    display: inline-block;
    padding: 5px 10px;
    font-size: 42px;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.65);
    transition: all 0.25s linear; }
    @media (max-width: 480px) {
      .caption__txt {
        font-size: 1.8rem; } }
    .caption__txt:hover {
      background-color: #dd4b39; }
  .caption__subtitle {
    display: inline-block;
    font-size: 28px;
    padding: 5px 10px;
    margin-top: 15px;
    background-color: rgba(0, 0, 0, 0.65); }
  .caption a.caption__subtitle:hover {
    background-color: #dd4b39; }

/*---------------------------------------
    FORM
---------------------------------------*/
.form {
  display: inline-block;
  text-align: center;
  background: #fff;
  padding: 30px 20px 20px;
  max-width: 380px;
  border-radius: 5px;
  line-height: 1.6; }
  .form .form__title {
    margin-bottom: 15px; }
  .form .form__subtitle {
    font-size: 12px;
    margin-bottom: 15px;
    color: #878787; }
  .form .form__footer {
    font-size: 12px;
    margin-bottom: 15px;
    color: #878787; }

.action-form .result .success {
  color: #388e3c;
  margin-bottom: 24px; }

.action-form .result .error {
  color: #b71c1c;
  margin-bottom: 24px; }

.action-form .form-group {
  display: block;
  text-align: left;
  margin-bottom: 15px; }

.action-form .form-label {
  display: block;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 3px; }
  .action-form .form-label .required {
    color: #b71c1c; }

.action-form .form-input {
  border: 1px solid #bababa;
  border-radius: 4px;
  padding: 8px 10px; }
  .action-form .form-input:focus {
    border-color: #212121; }

.action-form .form-btn {
  width: 100%;
  background: #ef5350;
  padding: 12px 16px; }
  .action-form .form-btn:hover {
    background-color: #d32f2f; }

.action-form .has-error {
  color: #b71c1c; }

.action-form .form-error {
  font-size: 0.8em;
  color: #b71c1c; }

.checkbox {
  cursor: pointer;
  display: block;
  position: relative;
  vertical-align: middle; }
  .checkbox input {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; }
  .checkbox .styling {
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    border: 1px solid #878787;
    border-radius: 2px;
    margin-right: 5px;
    position: relative; }
    .checkbox .styling:before {
      display: block;
      content: '';
      position: absolute;
      top: -8px;
      left: 0;
      color: #388e3c; }
  .checkbox .label {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: #878787; }
  .checkbox input[type=checkbox]:checked + .styling:before {
    content: '\2714'; }

/*---------------------------------------
    SECTIONS
---------------------------------------*/
.section {
  padding: 50px 0; }
  .section-colored {
    background-color: #f7f7f7; }
  .section-title {
    text-align: center;
    margin-bottom: 3rem; }
    .section-title h2 {
      margin-bottom: 15px; }
    .section-title--smaller h2 {
      font-size: 24px; }
    .section-title--upper {
      text-transform: uppercase; }
    .section-title-sub {
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      color: #3b3b3b; }

/*---------------------------------------
    CARDS
---------------------------------------*/
.cards {
  margin: 0 -10px; }
  .cards--push {
    margin-bottom: 30px; }
  .cards__item {
    margin-bottom: 1rem; }
    .cards__item--bordered {
      padding: 0 10px;
      margin-bottom: 20px; }
    @media (max-width: 640px) {
      .cards__item {
        margin-bottom: 2rem; } }
  @media (max-width: 900px) {
    .cards {
      margin: 0; } }

.card {
  padding: 0 20px;
  text-align: center;
  line-height: 26px;
  position: relative;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .card__body {
    flex: 0 1 100%;
    position: relative; }
  .card__num {
    width: 32px;
    height: 32px;
    background: #dd4b39;
    border-radius: 50%;
    color: #fff;
    line-height: 32px;
    margin: 15px auto; }
  .card__cont {
    padding-bottom: 20px; }
  .card__footer {
    flex: 0 1 100%;
    align-self: flex-end; }
  .card__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1rem; }
  .card__txt {
    font-size: 14px; }
    .card__txt p + p {
      margin-top: 15px; }
  .card__pic {
    flex: 0 1 100%;
    height: 160px;
    overflow: hidden;
    position: relative; }
    @media (max-width: 640px) {
      .card__pic {
        height: 220px; } }
  .card__price {
    font-weight: 600;
    margin-bottom: 10px; }
    .card__price--colored {
      color: #dd4b39; }
  .card--bordered {
    background: #fff;
    padding: 0 0 20px;
    border: 1px solid #ededed; }
    .card--bordered .card__cont {
      padding: 20px; }
  .card--full {
    padding-bottom: 0; }
    .card--full .card__cont {
      padding-right: 52%; }
      @media (max-width: 480px) {
        .card--full .card__cont {
          padding-right: 0; } }
    .card--full .card__footer {
      padding-right: 52%;
      margin-bottom: 20px; }
      @media (max-width: 480px) {
        .card--full .card__footer {
          padding-right: 0; } }
    .card--full .card__pic {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 50%; }
      @media (max-width: 480px) {
        .card--full .card__pic {
          position: static;
          width: 100%;
          height: 200px; } }
    .card--full .card__title {
      font-size: 32px;
      line-height: 1.4;
      text-transform: uppercase;
      margin-bottom: 30px; }
    .card--full .card__txt {
      font-size: 18px;
      margin-bottom: 30px; }

.img-bg {
  position: relative;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.25s linear; }
  .img-bg:hover {
    transform: scale(1.1); }
  .img-bg--top {
    background-position: top center; }
  .img-bg--no-zoom:hover {
    transform: none; }

.img {
  height: 100%;
  max-width: 100%;
  margin: 0 auto; }

/*---------------------------------------
    CLIENTS
---------------------------------------*/
.clients {
  text-align: center; }
  .clients__item {
    padding: 0 20px 20px;
    margin-bottom: 20px;
    vertical-align: middle;
    text-align: center; }

.client img {
  display: inline-block;
  width: 100%;
  transition: all 0.25s linear; }
  .client img:hover {
    transform: scale(1.1); }

/*---------------------------------------
    SLIDER
---------------------------------------*/
.slider-license {
  padding: 0 30px; }
  .slider-license .slick-arrow {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    margin-top: -15px;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    transition: all 0.25s linear; }
    .slider-license .slick-arrow:hover {
      color: #dd4b39; }
  .slider-license .slick-prev {
    left: 0; }
  .slider-license .slick-next {
    right: 0; }
  .slider-license .slick-dots {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center; }
    .slider-license .slick-dots li {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #cdcdcd;
      border-radius: 50%;
      margin: 15px 5px 0;
      overflow: hidden; }
      .slider-license .slick-dots li button {
        color: transparent; }
      .slider-license .slick-dots li.slick-active {
        background: #dd4b39; }

.slide {
  height: 400px;
  position: relative;
  z-index: 1; }
  @media (max-width: 640px) {
    .slide {
      height: 300px; } }
  @media (max-width: 480px) {
    .slide {
      height: 220px; } }
  .slide__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 100%;
    z-index: -1; }
  .slide__link {
    display: block;
    position: relative;
    height: 100%;
    z-index: 1; }
    .slide__link:before {
      display: block;
      content: '';
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      transition: all 0.25s linear; }
    .slide__link .zoom-in {
      display: block;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 50px;
      height: 50px;
      fill: #dd4b39;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      z-index: 10;
      transition: all 0.25s linear; }
    .slide__link:hover:before {
      visibility: visible;
      opacity: 1; }
    .slide__link:hover .zoom-in {
      visibility: visible;
      opacity: 0.8; }

/*---------------------------------------
    MAP
---------------------------------------*/
.map {
  height: 400px;
  overflow: hidden;
  position: relative; }
  .map iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%; }

/*---------------------------------------
    FOOTER
---------------------------------------*/
.footer {
  padding: 20px 0; }
  .footer__item {
    vertical-align: middle; }
    @media (max-width: 640px) {
      .footer__item {
        text-align: center;
        margin-bottom: 15px; } }

.footer-slogan {
  font-weight: 600; }

.footer-logo {
  text-align: center; }
  .footer-logo__img {
    display: inline-block;
    width: 100%;
    max-width: 230px; }

.footer-contacts {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right; }
  .footer-contacts li {
    display: block;
    margin-bottom: 5px; }
  .footer-contacts a {
    color: #212121;
    font-size: 12px; }
    .footer-contacts a:hover {
      color: #dd4b39; }
  @media (max-width: 640px) {
    .footer-contacts {
      text-align: center; } }

.footer-links {
  font-size: 10px;
  text-align: right; }
  .footer-links a {
    display: inline-block;
    margin: 0 5px;
    color: #212121; }
    .footer-links a:hover {
      color: #dd4b39; }
  @media (max-width: 640px) {
    .footer-links {
      text-align: center; } }

/*---------------------------------------
    MODAL
---------------------------------------*/
.modal {
  display: none;
  border-radius: 10px; }

.modal-text {
  max-width: 80%;
  padding: 40px 20px; }
