/* ELEMENTS */

/*---------- HAMBURGER ----------*/
.hamburger {
  cursor: pointer;
  display: block;
  height: 1.5rem; width: 2.5rem;
  position: relative;

  span, &:before, &:after {
    content: ''; display: block; position: absolute;
    width: 100%;
    height: 0.1875rem;
    background: $color-text;
    border-radius: 2px;
    @include transition;
  }
  &:before {
    top: 0;
  }
  span {
    top: 43%;
  }
  &:after {
    bottom: 0;
  }

  &.active {
    span {
      background: none;
    }
    &:before {
      top: 6px;
      transform: rotate(45deg);
    }
    &:after {
      top: 6px;
      transform: rotate(-45deg);
    }
  }
}

.app-author {
  text-align: center;
  text-transform: uppercase;
  color: lighten($color-text, 60%);
  font-size: 0.6em;
  padding: 1rem 0 0;
  &__link {
    color: lighten($color-text, 60%);
    &:hover {
      color: $color-prime;
    }
  }
}